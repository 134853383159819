.p-button {
  color: #ffffff;
  background: #4f46e5 !important;
  border: 1px solid #4f46e5;
  /* padding: 0.75rem 1rem; */
  font-size: 1rem;
  transition: none;
  border-radius: 0.375rem;
}
.p-button:enabled:hover {
  background: #4338ca;
  color: #ffffff;
  border-color: #4338ca;
}
.p-button:enabled:active {
  background: #4338ca;
  color: #ffffff;
  border-color: #4338ca;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #4f46e5;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(79, 70, 229, 0.04);
  color: #4f46e5;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(79, 70, 229, 0.16);
  color: #4f46e5;
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: #71717a;
  border-color: #71717a;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #f4f4f5;
  color: #71717a;
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #e5e7eb;
  color: #71717a;
}
.p-button.p-button-text {
  background-color: transparent;
  color: #4f46e5;
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: rgba(79, 70, 229, 0.04);
  color: #4f46e5;
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: rgba(79, 70, 229, 0.16);
  color: #4f46e5;
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: #71717a;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #f4f4f5;
  color: #71717a;
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: #e5e7eb;
  color: #71717a;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px #6366f1;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #4f46e5;
  background-color: #ffffff;
}
.p-button.p-button-raised {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.75rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 3rem;
}
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 0.875rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 1.25rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}
.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}
