@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  overflow-x: hidden;
  background-color: #f3f2f1;
}

.main-container {
  margin-left: 50px;
}

label {
  @apply text-gray-600;
  @apply text-sm;
}

.menu-left {
  transition: width 0.2s;
  width: 40px;
  @apply fixed;
  @apply left-0;
  @apply top-0;
  @apply h-screen;
  @apply shadow-lg;
  @apply overflow-y-auto;
  @apply bg-gray-800;
  z-index: 2;
}

.menu-left:hover .menu-left-expanded {
  display: block !important;
}

.p-button {
  color: #ffffff;
  background: #4f46e5 !important;
  border: 1px solid #4f46e5;
  /* padding: 0.75rem 1rem; */
  font-size: 1rem;
  transition: none;
  border-radius: 0.375rem;
}

.p-button:enabled:hover {
  background: #4338ca;
  color: #ffffff;
  border-color: #4338ca;
}

.p-button:enabled:active {
  background: #4338ca;
  color: #ffffff;
  border-color: #4338ca;
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: #4f46e5;
  border: 1px solid;
}

.p-button.p-button-outlined:enabled:hover {
  background: rgba(79, 70, 229, 0.04);
  color: #4f46e5;
  border: 1px solid;
}

.p-button.p-button-outlined:enabled:active {
  background: rgba(79, 70, 229, 0.16);
  color: #4f46e5;
  border: 1px solid;
}

.p-button.p-button-outlined.p-button-plain {
  color: #71717a;
  border-color: #71717a;
}

.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #f4f4f5;
  color: #71717a;
}

.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #e5e7eb;
  color: #71717a;
}

.p-button.p-button-text {
  background-color: transparent;
  color: #4f46e5;
  border-color: transparent;
}

.p-button.p-button-text:enabled:hover {
  background: rgba(79, 70, 229, 0.04);
  color: #4f46e5;
  border-color: transparent;
}

.p-button.p-button-text:enabled:active {
  background: rgba(79, 70, 229, 0.16);
  color: #4f46e5;
  border-color: transparent;
}

.p-button.p-button-text.p-button-plain {
  color: #71717a;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #f4f4f5;
  color: #71717a;
}

.p-button.p-button-text.p-button-plain:enabled:active {
  background: #e5e7eb;
  color: #71717a;
}

.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px #6366f1;
}

.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}

.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}

.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}

.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}

.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #4f46e5;
  background-color: #ffffff;
}

.p-button.p-button-raised {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.p-button.p-button-rounded {
  border-radius: 2rem;
}

.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.75rem 0;
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 3rem;
}

.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 0.875rem;
}

.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}

.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 1.25rem;
}

.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}

.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}

.p-autocomplete {
  display: inline-flex;
  position: relative;
}

.p-autocomplete .p-inputtext {
  @apply border-gray-300 rounded-md;
}

.p-autocomplete .p-inputtext:enabled:focus {
  @apply focus:outline-none focus:ring-indigo-500 focus:border-indigo-500;
}

.p-autocomplete .p-inputtext.ng-dirty.ng-invalid {
  border-color: #f0a9a7;
}

.p-autocomplete .p-inputtext.p-inputtext-sm {
  /* font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem; */
}

.p-autocomplete .p-inputtext.p-inputtext-lg {
  /* font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem; */
}

.p-autocomplete-loader {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-autocomplete-dd .p-autocomplete-input {
  flex: 1 1 auto;
  width: 1%;
}

.p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0px;
}

.p-autocomplete .p-autocomplete-panel {
  min-width: 100%;
  top: 0;
  left: 0;
}

.p-autocomplete-panel {
  position: absolute;
  overflow: auto;
}

.p-autocomplete-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-autocomplete-item {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.p-autocomplete-multiple-container {
  margin: 0;
  padding: 0;
  list-style-type: none;
  cursor: text;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.p-autocomplete-token {
  cursor: default;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
}

.p-autocomplete-token-icon {
  cursor: pointer;
}

.p-autocomplete-input-token {
  flex: 1 1 auto;
  display: inline-flex;
}

.p-autocomplete-input-token input {
  border: 0 none;
  outline: 0 none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
}

.p-fluid .p-autocomplete {
  display: flex;
}

.p-fluid .p-autocomplete-dd .p-autocomplete-input {
  width: 1%;
}

.p-autocomplete-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  cursor: pointer;
}

.p-autocomplete-clearable {
  position: relative;
}

.p-tabview-panels {
  padding: 0px !important;
}

.p-tabview-nav {
  background-color: transparent !important;
}

.p-tabview-nav-link {
  margin-right: 2px !important;
}

input[type=file]::-webkit-file-upload-button,
input[type=file]::file-selector-button {
  @apply text-white bg-gray-700 hover:bg-gray-600 font-medium text-sm cursor-pointer border-0 py-2.5 pl-8 pr-4;
  margin-inline-start: -1rem;
  margin-inline-end: 1rem;
}

.p-galleria-thumbnail-container {
  background-color: #efefef !important;
}