body {
  overflow-x: hidden;
  background-color: #f3f2f1;
}

.main-container {
  margin-left: 50px;
}

label {
  @apply text-gray-600;
  @apply text-sm;
}
